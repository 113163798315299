.header-of-home-page {
  text-align: center;
  background: #e6e7e9;
  padding: 1% 0 6% 0;
}

.slanted-div {
  position: relative;
  background: radial-gradient(
    circle at 100% 0%,
    rgba(54, 100, 244, 1) 0%,
    rgba(24, 37, 80, 1) 100%
  );
  overflow: visible;
  z-index: 1;
  bottom: 0%;
  width: 100%;
}

.slanted-div:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: left top;
  transform: skewY(-3deg);
}

.slanted-div * {
  color: white;
}

.keys-img {
  margin: 0 auto;
  max-width: 40%;
}

.actions-buttons {
  width: fit-content; 
  margin: 15px auto;
  background-color: var(--color-white);
  color: var(--color-p)
}

.actions-buttons:hover {
  width: fit-content; 
  margin: 25px auto;
  background-color: var(--color-p);
  color: var(--color-white)
}

@media screen and (max-width: 68rem) {
  .keys-img {
    max-width: 90%;
  }
}
