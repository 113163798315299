.btn-dashboard {
  max-width: 150px;
}
.controllers-wrapper {
  background-color: var(--color-light-gray);
  padding: 20px;
  border-radius: 15px;
  max-width: 350px;
  margin-bottom: 15px;
}

.titles-wrapper {
  margin: 0 auto;
  border-radius: 15px;
  text-align: center;
  max-width: 750px;
  margin-bottom: 15px;
}

.titles-wrapper h3 {
  color: var(--first-color);
}
