:root {
  --color-p: #3a5cd1;
  --color-white: #fff;
  --color-light-gray: #eff2f5;
  --color-mid-gray: #96a0b5;
  --color-dark-gray: #6d7d93;
  --color-dark: #182550;
  --color-red: #d13a3a;
}

.wrapper {
  font-size: 24px;
}

.wrapper h1 {
  font-size: 2.441rem;
}

.wrapper h2 {
  font-size: 1.953rem;
}

.wrapper h3 {
  font-size: 1.563rem;
}

.wrapper h4 {
  font-size: 1.25rem;
}

a {
  text-decoration: none;
  color: var(--color-p);
  transition: all 0.3s ease-out;
}
a:hover {
  color: var(--color-dark);
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

.wrapper {
  min-height: 93vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}
.left h1 {
  font-weight: 700;
  margin-bottom: 1rem;
}

.left-inner {
  display: flex;
  flex-flow: column wrap;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

label {
  display: block;
  color: var(--color-dark-gray);
  font-size: 21px;
}

input,
textarea {
  background-color: var(--color-light-gray);
  padding: 0.5rem;
  border: 1px solid transparent;
  width: 100%;
  border-radius: 6px;
  font-size: 1rem;
  font-family: inherit;
}

input:focus,
textarea:focus {
  color: var(--color-dark);
  background-color: var(--color-white);
  border-color: var(--color-p);
  outline: 0;
  border: 1px solid var(--color-p);
}

input.error {
  border: 1.5px solid var(--color-red);
}

.error-text {
  color: var(--color-red);
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-p);
  color: var(--color-white);
  border: 0;
  padding: 0.75rem;
  width: 100%;
  border-radius: 6px;
  font-family: inherit;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
button:hover {
  background-color: var(--color-dark);
}

.right {
  background: radial-gradient(
    82.76% 57.9% at 72.85% 4.95%,
    var(--color-p) 0%,
    var(--color-dark) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  padding: 3rem;
}
.right svg {
  margin: auto;
}
.right h2 {
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.right p {
  max-width: 50vw;
  margin: 0 auto;
}

@media screen and (max-width: 48rem) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .right {
    display: none;
  }
}

.hidden-upload-file {
  position: absolute;
  top: -1000px;
}

.sign-in-form {
  max-width: 80%;
}
