/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

/*===== VARIABLES CSS =====*/
:root {
  --header-height: 3rem;

  /*===== Colores =====*/
  --first-color: #3664f4;
  --dark-color: #070d1f;
  --dark-color-alt: #282b3a;
  --white-color: #e6e7e9;

  /*===== Fuente y tipografia =====*/
  --body-font: "Poppins", sans-serif;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;

  /*===== z index =====*/
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
  }
}

/*===== BASE =====*/
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: 500;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.nav__img img {
  max-width: 100%;
  height: auto;
  clip-path: circle();
}

.bd-grid {
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

/*===== HEADER =====*/
.header {
  width: 100%;
  height: var(--header-height);
  top: 0;
  left: 0;
  padding: 0 1.5rem;
  background-color: var(--dark-color);
  z-index: var(--z-fixed);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  color: var(--white-color);
}

.header__toggle {
  font-size: 1.5rem;
  color: var(--white-color);
  cursor: pointer;
}

.hidden {
  display: none;
}

/*===== NAV =====*/
@media screen and (max-width: 768px) {
  .header {
    position: fixed;
  }
  .nav {
    position: fixed;
    top: 0;
    left: -100%;
    background-color: var(--dark-color);
    color: var(--white-color);
    width: 50%;
    min-width: 200px;
    height: 100vh;
    padding: 1.5rem 0;
    z-index: var(--z-fixed);
    transition: 0.5s;
  }
}

.nav__content {
  height: 100%;
  grid-template-rows: max-content 1fr max-content;
  row-gap: 2rem;
}

.nav__close {
  position: absolute;
  right: 1.5rem;
  font-size: 1.3rem;
  padding: 0.25rem;
  background-color: var(--dark-color-alt);
  border-radius: 50%;
  cursor: pointer;
}

.nav__img {
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem;
  width: 56px;
  height: 56px;
  background-color: var(--first-color);
  border-radius: 50%;
  overflow: hidden;
}

/* .nav__img img {
  width: 46px;
} */

.nav__name {
  display: block;
  color: var(--white-color);

  text-align: left;
}

.nav__profesion {
  font-size: var(--small-font-size);
}

.nav__menu {
  align-self: center;
}

.nav__item {
  margin: 2.5rem 0;
}

.nav__link {
  color: var(--white-color);
}

/*Aparecer menu*/
.show {
  left: 0;
}

/*Active menu*/
.active {
  color: var(--first-color);
}

/*===== MEDIA QUERIES =====*/
@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }
  .header {
    height: calc(var(--header-height) + 1rem);
  }
  .header__logo,
  .header__toggle {
    display: none;
  }
  .nav {
    width: 100%;
  }
  .nav__content {
    grid-template-columns: repeat(3, -webkit-max-content);
    grid-template-columns: repeat(3, max-content);
    grid-template-rows: 1fr;
    column-gap: 1rem;
    justify-content: space-between;
  }
  .nav__close,
  .nav__profesion {
    display: none;
  }
  .nav__perfil {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .nav__img {
    width: 32px;
    height: 32px;
    margin-right: 0.5rem;
    margin-bottom: 0;
    align-items: center;
  }
  /* .nav__img img {
    width: 26px;
  } */
  .nav__list {
    display: flex;
  }
  .nav__item {
    margin: 0 0.25rem;
  }
  .nav__link {
    padding: 0.5rem 0.8rem;
    border-radius: 0.25rem;
  }
  .nav__link:hover {
    background-color: var(--first-color);
  }
  .active {
    background-color: var(--first-color);
    color: var(--white-color);
  }
}

@media screen and (min-width: 1024px) {
  .bd-grid {
    margin-left: auto;
    margin-right: auto;
  }
}
